<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page illustration -->
      <div class="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
        <PageIllustration />
      </div>

      <!-- Page sections -->
      <HeroFAQ />
      <section class="relative">

        <div class="relative max-w-6xl mx-auto px-4 sm:px-6 pb-12">
          <div class="pt-4 pb-2 md:pt-3 md:pb-2">

            <div class="max-w-3xl" data-aos="fade-down">
              <h4 class="h4">What is this?</h4>
              <p class="text-xl text-gray-600 dark:text-gray-400">
                Alpha Squeeze attempts to help you trade more profitably. It tries to do this in two ways. Firstly, by helping you manage risk with the calculator. Secondly, by helping you evaluate your trading habits with a journal and stats. At the end of the day though, it's just a tool. As with any tool, it can be worthless or invaluable depending on how you use it.
              </p>
            </div>

            <div class="max-w-3xl pt-8 md:pt-6" data-aos="fade-down">
              <h4 class="h4">Will you have access to my trading data?</h4>
              <p class="text-xl text-gray-600 dark:text-gray-400">
                Your trading data is only ever stored on your phone and your iCloud account (if turned on). Additionally, Alpha Squeeze never asks you to register or requires any personal information from you
              </p>
            </div>

            <div class="max-w-3xl pt-8 md:pt-6" data-aos="fade-down">
              <h4 class="h4">Can I export my data?</h4>
              <p class="text-xl text-gray-600 dark:text-gray-400">
                Not yet! But soon. Exporting trading data is something that could be very useful for people. Once implemented, you'll be able to analyze exported data in your favorite spreadsheet application or import it into other software.
              </p>
            </div>

            <div class="max-w-3xl pt-8 md:pt-6" data-aos="fade-down">
              <h4 class="h4">Is my data backed up?</h4>
              <p class="text-xl text-gray-600 dark:text-gray-400">
                Yep. Your trading journal is automatically synced to your iCloud account. If this gets disabled on your device, simply go to Settings > Your Apple ID / iCloud account > iCloud and make sure Alpha Squeeze is enabled.
              </p>
            </div>

            <div class="max-w-3xl pt-8 md:pt-6" data-aos="fade-down">
              <h4 class="h4">Will my data be synced across my devices?</h4>
              <p class="text-xl text-gray-600 dark:text-gray-400">
                As long as iCloud is enabled for Alpha Squeeze, your trading data will be synced across your iOS devices.
              </p>
            </div>

            <div class="max-w-3xl pt-8 md:pt-6" data-aos="fade-down">
              <h4 class="h4">Where can I suggest features or report bugs?</h4>
              <p class="text-xl text-gray-600 dark:text-gray-400">
                Your feedback is very much appreciated. From inside the app, there's a Feedback link in the Settings tab. Alternatively, there's a contact link in the footer of this page.
              </p>
            </div>

            <div class="max-w-3xl pt-8 md:pt-6" data-aos="fade-down">
              <h4 class="h4">What does this cost?</h4>
              <p class="text-xl text-gray-600 dark:text-gray-400">
                Alpha Squeeze allows you to create some free entries to give you a feel for the app. Beyond that, there's a small monthly fee to unlock full access. Your support is much appreciated and allows me to keep maintaining the app. Also, it's worth mentioning that if you jump on the current introductory price, you'll lock in that same price for as long as you're a subscriber (even price rises later).
              </p>
            </div>

            <div class="max-w-3xl pt-8 md:pt-6" data-aos="fade-down">
              <h4 class="h4">What's with the entries when I first open the app?</h4>
              <p class="text-xl text-gray-600 dark:text-gray-400">
                Some demo entries gets created when you first install the app to help illustrate what entries look like. You're free to edit or delete them as you wish.
              </p>
            </div>

          </div>
        </div>

      </section>

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import PageIllustration from './../partials/PageIllustration.vue'
import HeroFAQ from "./../partials/HeroFAQ.vue";
import Footer from './../partials/Footer.vue'

export default {
  name: 'FAQ',
  created () {
    document.title = "FAQ";
  },
  components: {
    Header,
    PageIllustration,
    HeroFAQ,
    Footer,
  },
};
</script>
